export enum SirenTests {
  Delay = 'Start Delay',
  AllClear = 'Start All Clear',
  Light = 'Turn On Light',
  PAMessage = 'Send PA Message',
}

export enum HardwareFilterTypes {
  ALL = 'All',
  OWS = 'Outdoor Warning System',
  WXS = 'Weather Station',
}

export enum HardwareTypes {
  OWX = 'OWX',
  OWS = 'OWS',
  WXS = 'WXS',
}

export enum ThreatLevel {
  NONE = 0,
  MODERATE = 1,
  SEVERE = 2,
}

export type PolicyTypeName =
  | 'WBGT'
  | 'WIND'
  | 'GUST'
  | 'AQI'
  | 'CHILL'
  | 'HEAT'
  | 'PRECIP'
  | 'LR1'
  | 'LR2'
  | 'LR3'
  | 'AQI-PM2.5'
  | 'AQI-PM10'
  | 'AQI-O3'
  | 'AQI-MAX';

export type ThreatTypes = PolicyTypeName | 'NWS' | 'NONE';

export enum UserRoles {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  AssistantAdmin = 'Assistant Admin',
  Customer = 'Customer',
}

export enum CustomerEntitlements {
  GPSTracking = 'gps.tracking',
  MetSupport = 'met.support',
  PublicAddress = 'pa.full_access',
  AirQualityIndex = 'aqi.access',
  MassNotify = 'massnotify.access',
  WidgetAccess = 'widget.access',
  LiveCamera = 'live_camera.access',
  FourthLightningZone = 'fourth_lightning_zone.support',
  IntegrationsSinglewire = 'integrations.singlewire.access',
  IntegrationsSSO = 'integrations.sso.access',
  BroadcastViewAccess = 'broadcast.view.access',
  RoadConditionsAccess = 'map.road_conditions.access',
  Aqi3pAccess = 'aqi.3paccess',
  ProactiveForecastAccess = 'proactive_forecast.access',
  HistoricalReportAccess = 'historical_report.access'
}
