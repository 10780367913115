import Container from '@mui/material/Container';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ConsultationIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ExtensionOutlined from '@mui/icons-material/ExtensionOutlined';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import TeamIcon from '@mui/icons-material/GroupOutlined';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useLocation } from 'wouter';
import { selectMenu, toggle } from '../../features/menu/menuSlice';
import { useAppSelector } from '../../app/hooks';
import { PWLogo } from '../../Assets/PWLogo';
import { FactCheck as PolicyIcon, Send, VideoCameraBackOutlined } from '@mui/icons-material';
import { Theme, Typography, useMediaQuery, useTheme, Tooltip as MuiTooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

import './Sidebar.css';
import { useDispatch } from 'react-redux';
import { CellularNetworkIcon } from '../../Assets/CellularNetwork';
import { Sidebar as PWSidebar, SidebarListItem, MobileSidebarListItem } from '@perry-weather/component-library';
import { AirQuality } from '../../Assets';
import PermissionGate from './PermissionGate';
import { CustomerEntitlements, UserRoles } from '../../Constants/Constants';
import { TrackableEvents } from '../Tracked/events';
import { withClickTracking } from '../Tracked/withMixpanelTracking';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const TrackedLink = withClickTracking(Link)(TrackableEvents.ButtonClicked, {});
const TrackedListItemIcon = withClickTracking(ListItemIcon)(TrackableEvents.ButtonClicked, {});

const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.background.disabled,
  },
  tooltip: {
    backgroundColor: theme.palette.background.disabled,
  },
}));

// Maps a path to a name. As new pages get added, add them here so that things like Mixpanel tracking and the sidebar can be updated easily.
const pageNameMapping: { [path: string]: string } = {
  '/': 'Home',
  '/hardware': 'On-Site Hardware',
  '/airquality': 'Air Quality Index',
  '/consultation': 'Ask a Meteorologist',
  '/users': 'Users',
  '/policies': 'Policies',
  '/massnotify': 'Notify Users',
  '/widgets': 'Widgets',
  '/livecamera': 'Live Camera',
  '/integrations': 'Integrations',
  '/broadcast': 'Broadcast',
};

export default function Sidebar() {
  const theme = useTheme();

  const hidden = useMediaQuery(theme.breakpoints.up('sm'));

  const mobileOpen = useAppSelector(selectMenu);
  const dispatch = useDispatch();
  const [location] = useLocation();

  const { SuperAdmin, Admin, AssistantAdmin } = UserRoles;
  const url = location.toLocaleLowerCase();
  const tooltipStyles = useTooltipStyles();

  const SidebarTooltip = (props: { title: string; children: React.ReactElement }) => {
    return (
      <MuiTooltip
        classes={tooltipStyles}
        title={<Typography>{props.title}</Typography>}
        arrow
        placement='right'
        enterDelay={300}
        leaveDelay={200}>
        {props.children}
      </MuiTooltip>
    );
  };

  const baseTrackingData = {
    button_location: pageNameMapping[url] || 'Unknown',
    button_feature: 'sidebar',
    exit_type: 'internal_navigation',
    previous_page_name: pageNameMapping[url] || 'Unknown',
    previous_page_path: url,
  };

  useEffect(() => {
    if (mixpanel.__loaded) {
      mixpanel.time_event(TrackableEvents.NavigationClicked);
    }
  }, [mixpanel.__loaded]);

  const desktopDrawer = () => (
    <PWSidebar mobileOpen={false}>
      <List>
        <SidebarListItem>
          <TrackedLink to='/' mpExtraData={{ ...baseTrackingData, next_page_name: 'Home', button_label: 'Home' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </TrackedLink>
        </SidebarListItem>
        <SidebarListItem selected={url === '/'}>
          <Link to='/'>
            <TrackedListItemIcon
              mpExtraData={{ ...baseTrackingData, next_page_name: 'Home', next_page_url: '/', button_label: 'Home' }}>
              <SidebarTooltip title='Home'>
                <div className='svg-container'>
                  <HomeIcon />
                </div>
              </SidebarTooltip>
            </TrackedListItemIcon>
          </Link>
        </SidebarListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/hardware'}>
            <Link to='/Hardware'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'On-Site Hardware',
                  next_page_url: '/hardware',
                  button_label: 'On-Site Hardware',
                }}>
                <SidebarTooltip title='On-Site Hardware'>
                  <div className='svg-container'>
                    <CellularNetworkIcon width={24} height={24} color={theme.palette.action.inactive} />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <SidebarListItem selected={url === '/airquality'}>
            <Link to='/AirQuality' onClick={() => dispatch(toggle())}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Air Quality Index',
                  next_page_url: '/airquality',
                  button_label: 'Air Quality Index',
                }}>
                <SidebarTooltip title='Air Quality Index'>
                  <div className='svg-container'>
                    <AirQuality height={24} width={24} color={theme.palette.action.inactive} />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <SidebarListItem selected={url === '/consultation'}>
            <Link to='/Consultation'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Ask a Meteorologist',
                  next_page_url: '/consultation',
                  button_label: 'Ask a Meteorologist',
                }}>
                <SidebarTooltip title='Ask a Meteorologist'>
                  <div className='svg-container'>
                    <ConsultationIcon />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/users'}>
            <Link to='/Users'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Users',
                  next_page_url: '/users',
                  button_label: 'Users',
                }}>
                <SidebarTooltip title='Users'>
                  <div className='svg-container'>
                    <TeamIcon />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <SidebarListItem selected={url === '/policies'}>
            <Link to='/Policies'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Policies',
                  next_page_url: '/policies',
                  button_label: 'Policies',
                }}>
                <SidebarTooltip title='Policies'>
                  <div className='svg-container'>
                    <PolicyIcon />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <SidebarListItem selected={url === '/massnotify'}>
            <Link to='/MassNotify'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Notify Users',
                  next_page_url: '/massnotify',
                  button_label: 'Notify Users',
                }}>
                <SidebarTooltip title='Notify Users'>
                  <div className='svg-container'>
                    <Send />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <SidebarListItem selected={url === '/widgets'}>
            <Link to='/Widgets'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Widgets',
                  next_page_url: '/widgets',
                  button_label: 'Widgets',
                }}>
                <SidebarTooltip title='Widgets'>
                  <div className='svg-container'>
                    <WidgetsOutlinedIcon />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <SidebarListItem selected={url === '/livecamera'}>
            <Link to='/LiveCamera'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Live Camera',
                  next_page_url: '/livecamera',
                  button_label: 'Live Camera',
                }}>
                <SidebarTooltip title='Live Camera'>
                  <div className='svg-container'>
                    <VideoCameraBackOutlined />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.IntegrationsSinglewire, CustomerEntitlements.IntegrationsSSO]}>
          <SidebarListItem selected={url === '/integrations'}>
            <Link to='/Integrations'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Integrations',
                  next_page_url: '/integrations',
                  button_label: 'Integrations',
                }}>
                <SidebarTooltip title='Integrations'>
                  <div className='svg-container'>
                    <ExtensionOutlined />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          <SidebarListItem selected={url === '/broadcast'}>
            <Link to='/Broadcast'>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Broadcast',
                  next_page_url: '/broadcast',
                  button_label: 'Broadcast',
                }}>
                <SidebarTooltip title='Broadcast'>
                  <div className='svg-container'>
                    <PodcastsIcon />
                  </div>
                </SidebarTooltip>
              </TrackedListItemIcon>
            </Link>
          </SidebarListItem>
        </PermissionGate>
      </List>
    </PWSidebar>
  );

  const closeDrawer = () => dispatch(toggle());

  const mobileDrawer = () => (
    <PWSidebar mobileOpen={mobileOpen} onClose={closeDrawer}>
      <List>
        <MobileSidebarListItem>
          <TrackedLink
            to='/'
            onClick={closeDrawer}
            mpExtraData={{ ...baseTrackingData, next_page_name: 'Home', next_page_url: '/', button_label: 'Home' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PWLogo color={theme.palette.background.default} />
            </div>
          </TrackedLink>
        </MobileSidebarListItem>
        <MobileSidebarListItem selected={url === '/'}>
          <Link to='/' onClick={closeDrawer}>
            <TrackedListItemIcon
              mpExtraData={{ ...baseTrackingData, next_page_name: 'Home', next_page_url: '/', button_label: 'Home' }}>
              <div className='svg-container'>
                <HomeIcon fontSize='large' />
                <Typography fontSize='22px'>Home</Typography>
              </div>
            </TrackedListItemIcon>
          </Link>
        </MobileSidebarListItem>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/hardware'}>
            <Link to='/Hardware' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'On-Site Hardware',
                  next_page_url: '/hardware',
                  button_label: 'On-Site Hardware',
                }}>
                <div className='svg-container'>
                  <CellularNetworkIcon height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>On-Site Hardware</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.AirQualityIndex]}>
          <MobileSidebarListItem selected={url === '/airquality'}>
            <Link to='/AirQuality' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Air Quality Index',
                  next_page_url: '/airquality',
                  button_label: 'Air Quality Index',
                }}>
                <div className='svg-container'>
                  <AirQuality height={36} width={36} color={theme.palette.action.inactive} />
                  <Typography fontSize='22px'>Air Quality Index</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MetSupport]}>
          <MobileSidebarListItem selected={url === '/consultation'}>
            <Link to='/Consultation' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Ask a Meteorologist',
                  next_page_url: '/consultation',
                  button_label: 'Ask a Meteorologist',
                }}>
                <div className='svg-container'>
                  <ConsultationIcon fontSize='large' />
                  <Typography fontSize='22px'>Ask A Meteorologist</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/users'}>
            <Link to='/Users' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Users',
                  next_page_url: '/users',
                  button_label: 'Users',
                }}>
                <div className='svg-container'>
                  <TeamIcon fontSize='large' />
                  <Typography fontSize='22px'>Users</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}>
          <MobileSidebarListItem selected={url === '/policies'}>
            <Link to='/Policies' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Policies',
                  next_page_url: '/policies',
                  button_label: 'Policies',
                }}>
                <div className='svg-container'>
                  <PolicyIcon fontSize='large' />
                  <Typography fontSize='22px'>Policies</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.MassNotify]}>
          <MobileSidebarListItem selected={url === '/massnotify'}>
            <Link to='/MassNotify' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Notify Users',
                  next_page_url: '/massnotify',
                  button_label: 'Notify Users',
                }}>
                <div className='svg-container'>
                  <Send fontSize='large' />
                  <Typography fontSize='22px'>Notify Users</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.WidgetAccess]}>
          <MobileSidebarListItem selected={url === '/widgets'}>
            <Link to='/Widgets' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Widgets',
                  next_page_url: '/widgets',
                  button_label: 'Widgets',
                }}>
                <div className='svg-container'>
                  <WidgetsOutlinedIcon fontSize='large' />
                  <Typography fontSize='22px'>Widgets</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.LiveCamera]}>
          <MobileSidebarListItem selected={url === '/livecamera'}>
            <Link to='/LiveCamera' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Live Camera',
                  next_page_url: '/livecamera',
                  button_label: 'Live Camera',
                }}>
                <div className='svg-container'>
                  <VideoCameraBackOutlined fontSize='large' />
                  <Typography fontSize='22px'>Live Camera</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate allowedRoles={[SuperAdmin, Admin, AssistantAdmin]} allowedEntitlements={[]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <MobileSidebarListItem selected={url === '/integrations'}>
            <Link to='/Integrations' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Integrations',
                  next_page_url: '/integrations',
                  button_label: 'Integrations',
                }}>
                <div className='svg-container'>
                  <ExtensionOutlined fontSize='large' />
                  <Typography fontSize='22px'>Integrations</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
        <PermissionGate
          allowedRoles={[SuperAdmin, Admin, AssistantAdmin]}
          allowedEntitlements={[CustomerEntitlements.BroadcastViewAccess]}>
          {' '}
          {/* TODO: remove this when the feature is ready to launch, as integrations are not tied to an entitlement */}
          <MobileSidebarListItem selected={url === '/broadcast'}>
            <Link to='/Broadcast' onClick={closeDrawer}>
              <TrackedListItemIcon
                mpExtraData={{
                  ...baseTrackingData,
                  next_page_name: 'Broadcast',
                  next_page_url: '/broadcast',
                  button_label: 'Broadcast',
                }}>
                <div className='svg-container'>
                  <PodcastsIcon fontSize='large' />
                  <Typography fontSize='22px'>Broadcast</Typography>
                </div>
              </TrackedListItemIcon>
            </Link>
          </MobileSidebarListItem>
        </PermissionGate>
      </List>
    </PWSidebar>
  );

  return <Container>{hidden ? desktopDrawer() : mobileDrawer()}</Container>;
}
