import { Box, Typography } from '@mui/material';
import { Button, Card } from '@perry-weather/component-library';
import PermissionGate from '../../Shared/PermissionGate';
import { CustomerEntitlements } from '../../../Constants/Constants';
import { useLocation } from 'wouter';
import { withClickTracking } from '../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../Tracked/events';

const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Integrations',
  button_feature: 'Integrations SSO',
  button_type: 'button',
});

export const SSOIntegrationCard = () => {
  const setLocation = useLocation()[1];

  const onSSOContactClick = () => {
  };

  const onSSOManageClick = () => {
    setLocation('/Integrations/SSO');
  };

  return (
    <Card header='Single Sign-On (SSO)'>
      <img src='/sso-logo.png' alt='SSO Logo' style={{ borderRadius: 4, marginBottom: 24 }} />
      <Typography variant='body1' color='textPrimary' style={{ marginBottom: 16 }}>
        Enable users to log in with your identity provider through SSO authentication
      </Typography>

      <PermissionGate
        allowedEntitlements={[CustomerEntitlements.IntegrationsSSO]}
        fallback={
            <Box className="sso-request"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
            <TrackedButton
                type='primary'
                onClick={onSSOContactClick}
                mpExtraData={{ button_text: 'Contact for Pricing' }}>
                Contact for Pricing
            </TrackedButton>
          </Box>
        }>
        <TrackedButton
          type='primary'
          onClick={onSSOManageClick}
          mpExtraData={{ button_text: 'Manage SSO Configuration' }}>
          Manage SSO Configuration
        </TrackedButton>
      </PermissionGate>
    </Card>
  );
};
