import { Page } from '@perry-weather/component-library';
import { Grid, Typography } from '@mui/material';
import { SinglewireIntegrationCard } from '../Components/Integrations/Singlewire/IntegrationCard';
import { SSOIntegrationCard } from '../Components/Integrations/SSO/IntegrationCard';
import { withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';
import { useAuth } from 'oidc-react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Integrations' });

export function Integrations() {

  // we're storing user email in local storage so the marketing teams widget can extract that and create a
  // request for integrations.
  const { userData: user } = useAuth();
  useEffect ( () => {
    if (user?.profile?.email) {
        localStorage.setItem('pw_user_email', user.profile.email);
    }
  }, [user]);

  return (
    <TrackedPage
      docTitle='Integrations'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Integrations
        </Typography>
      }
      subheader={
        <Typography variant='body1' color='textSecondary'>
          Review and manage your enabled integrations
        </Typography>
      }>
      <Helmet>
        <script src="https://static.personizely.net/a5bc6cb24e.js" type="text/javascript"></script>
      </Helmet>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          {/* All future integrations can be listed here and handle their own configuration / management */}
          <SinglewireIntegrationCard />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <SSOIntegrationCard />
        </Grid>
      </Grid>
    </TrackedPage>
  );
}
export default Integrations;
