import {
  Dashboard,
  Hardware,
  Historical,
  Notifications,
  Consultation,
  Users,
  NotificationSettings,
  Policies,
  Profile,
  Unauthorized,
  PAStoredMessages,
  MassNotify,
  AirQuality,
} from './Pages';
import { Counter } from './features/counter/Counter';
import { Route, useLocation } from 'wouter';
import Menu from './Components/Shared/MainMenu/MainMenu';
import Callback from './Pages/Callback';
import SilentRenew from './Pages/SilentRenew';
import { useAuth } from 'oidc-react';
import { useDispatch } from 'react-redux';
import { getNeedsLogin, setCredentials } from './Services/Auth/authSlice';

import { useAppSelector } from './app/hooks';
import { useEffect, useMemo } from 'react';
import { Widgets } from './Pages/Widgets';
import { WidgetBuilder } from './Pages/WidgetBuilder';
import { LiveCamera } from './Pages/LiveCamera';
import { LiveCameraLogs } from './Pages/LiveCameraLogs';
import { Integrations } from './Pages/Integrations';
import { IntegrationsSinglewire } from './Pages/IntegrationsSinglewire';
import { BroadcastView } from './Pages/BroadcastView';
import CustomerBanner from './Components/Shared/MainMenu/CustomerBanner';
import { IntegrationsSSO } from './Pages/IntegrationsSSO';

export default function Routes() {
  let auth = useAuth();
  let dispatch = useDispatch();
  const needsLogin: Boolean = useAppSelector(getNeedsLogin);

  const [location] = useLocation();

  useEffect(() => {
    if (!auth.isLoading) return;

    let signInTimeout = setTimeout(() => {
      console.debug('sign in timed out');
      window.location.replace('/');
    }, 120000);

    return () => {
      clearTimeout(signInTimeout);
    };
  }, [auth]);

  useMemo(() => {
    window.gtag('set', 'page_path', location);
    window.gtag('event', 'page_view');
  }, [location]);

  // window.location.hash
  if (auth.isLoading) {
    return <>Loading...</>;
  } else if (auth.userData && !needsLogin) {
    dispatch(setCredentials({ token: auth.userData!.access_token }));
    return (
      <>
        <Menu />
        <CustomerBanner />
        <Route path='/Counter'>
          <Counter />
        </Route>
        <Route path='/Hardware' component={Hardware} />
        <Route path='/Locations/:name'>{(params: any) => <div>Hello, {params.name}!</div>}</Route>
        <Route path='/' component={Dashboard} />
        <Route path='/Notifications' component={Notifications} />
        <Route path='/NotificationSettings' component={NotificationSettings} />
        <Route path='/Consultation' component={Consultation} />
        <Route path='/Callback' component={Callback} />
        <Route path='/SilentRenew' component={SilentRenew} />
        <Route path='/Users' component={Users} />
        <Route path='/Policies' component={Policies} />
        <Route path='/AirQuality' component={AirQuality} />
        <Route path='/Profile' component={Profile} />
        <Route path='/PAStoredMessages' component={PAStoredMessages} />
        <Route path='/MassNotify' component={MassNotify} />
        <Route path='/Historical/:weatherStationId' component={Historical} />
        <Route path='/Unauthorized' component={Unauthorized} />
        <Route path='/Widgets' component={Widgets} />
        <Route path='/Widgets/:customerId/:widgetId' component={WidgetBuilder} />
        <Route path='/LiveCamera' component={LiveCamera} />
        <Route path='/LiveCamera/Logs/:customerId' component={LiveCameraLogs} />
        <Route path='/Integrations' component={Integrations} />
        <Route path='/Integrations/Singlewire' component={IntegrationsSinglewire} />
        <Route path='/Integrations/SSO' component={IntegrationsSSO} />
        <Route path='/Broadcast' component={BroadcastView} />
      </>
    );
    // return (
    // 	<div style={{ position: 'absolute', left: 0, top: 0, height: '100vh', width: '100%', display: 'flex', alignItems: 'center' }} >
    // 		<Grid container margin={1} alignContent="center" alignItems="center" display='flex' flexDirection="column" justifyContent="center" spacing={1}>
    // 			<Grid item>
    // 				<LockIcon style={{width: 85, height: 85}} htmlColor={theme.palette.primary.main} />
    // 			</Grid>

    // 			<Grid item>
    // 				<Typography variant="h4" noWrap>
    // 					Unauthorized
    // 				</Typography>
    // 			</Grid>

    // 			<Grid item width='90%'>
    // 				<Typography variant="body1">
    // 					If you have been granted access recently, you may have to sign out and sign back in.
    // 				</Typography>
    // 			</Grid>

    // 			<Grid item>
    // 				<Button onClick={() => auth.userManager.signoutRedirect()}>Sign Out</Button>
    // 			</Grid>
    // 		</Grid>
    // 	</div>);
  } else {
    if (needsLogin) {
      // console.debug('attempting to relogin.');
    }

    if (window.location.pathname !== '/callback' && window.location.pathname !== '/silentrenew')
      window.sessionStorage.setItem('auth:redirect', window.location.pathname);

    auth.signIn();

    return <></>;
  }
}
