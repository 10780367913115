// import { ssoApi } from '.';
import { api } from '../api';

export interface SSOConfig {
  id?: number;
  customerId: number;
  forceSSO: boolean;
  autoProvision: boolean;
  defaultUserGroupSiteId: number;
  idpDisplayName: string;
  idpScheme: string;
}

// Request type for creating/updating SSO config
export interface SSOConfigRequest {
  customerId: number;
  ForceSSO: boolean;
  AutoProvision: boolean;
  DefaultUserGroupSiteId: number;
  IDPDisplayName: string;
  IDPScheme: string;
}

interface SSOResponse {
  message: string;
  data: SSOConfig;
}

const ssoEndpoints = api.injectEndpoints({
  endpoints: builder => ({
    getSSOConfig: builder.query<SSOConfig, number>({
      query: (customerId) => `customer/${customerId}/sso`,
      transformResponse: (response: SSOResponse) => response.data,
      providesTags: ['Integrations.SSO'],
    }),

    updateSSOConfig: builder.mutation<SSOConfig, SSOConfigRequest>({
      query: (body) => ({
        url: `customer/${body.customerId}/sso`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: SSOResponse) => response.data,
      invalidatesTags: ['Integrations.SSO'],
    }),

    deleteSSOConfig: builder.mutation<void, number>({
      query: (customerId: number) => ({
        url: `customer/${customerId}/sso`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Integrations.SSO'],
    }),
  }),
});

export const {
  useGetSSOConfigQuery,
  useUpdateSSOConfigMutation,
  useDeleteSSOConfigMutation,
} = ssoEndpoints;
