import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Button, Card, Checkbox, Page, Select, useDocumentTitle } from '@perry-weather/component-library';
import { useAuth } from 'oidc-react';
import { Redirect } from 'wouter';
import { UserRoles } from '../Constants/Constants';
import { withClickTracking, withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';
import { styledLinkClass, SubCardContainer } from '../Components/Base';
import { SiteSelect } from '../Components/Team/SiteSelect';
import { useGetSSOConfigQuery, useUpdateSSOConfigMutation } from '../Services/API/sso/ssoApi';
import { useUserQuery } from '../Services/API';
import { useState, useEffect } from 'react';
import { openToast } from '../features/toast/toastSlice';
import { useDispatch } from 'react-redux';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Integrations SSO' });
const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
    button_location: 'Integration SSO',
    button_feature: 'Integration SSO',
    button_type: 'button',
});
const TrackedLink = withClickTracking(({ onClick, children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a onClick={onClick} {...props}>
        {children}
    </a>
))(TrackableEvents.ButtonClicked, {
    button_location: 'Integration SSO',
    button_feature: 'Help Center',
    button_type: 'link',
});

export function IntegrationsSSO() {
  const { userData } = useAuth();
  const { data: user } = useUserQuery();
  const dispatch = useDispatch();
  const theme = useTheme();
  const linkClass = styledLinkClass(theme);

  const [formState, setFormState] = useState({
    idpDisplayName: 'Google',
    idpScheme: 'Google',
    autoProvision: false,
    forceSSO: false,
    defaultUserGroupSiteId: 0
  });

  const { data: ssoConfig, isLoading, isError } = useGetSSOConfigQuery(user?.customerId ?? 0, {
    skip: !user?.customerId
  });

  const [updateSSO, { isSuccess: updateSuccess, isError: updateError }] = useUpdateSSOConfigMutation();

  useEffect(() => {
    if (ssoConfig) {
      setFormState({
        idpDisplayName: ssoConfig.idpDisplayName,
        idpScheme: ssoConfig.idpScheme,
        autoProvision: ssoConfig.autoProvision,
        forceSSO: ssoConfig.forceSSO,
        defaultUserGroupSiteId: ssoConfig.defaultUserGroupSiteId
      });
    }
  }, [ssoConfig]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(openToast({ variant: 'success', header: 'SSO configuration updated successfully' }));
    } else if (updateError) {
      dispatch(openToast({ variant: 'error', header: 'Failed to update SSO configuration' }));
    }
  }, [updateSuccess, updateError]);

  const handleSubmit = async () => {
    if (!user?.customerId) return;

    try {
      await updateSSO({
        customerId: user.customerId,
        ForceSSO: formState.forceSSO,
        AutoProvision: formState.autoProvision,
        DefaultUserGroupSiteId: formState.defaultUserGroupSiteId,
        IDPDisplayName: formState.idpDisplayName,
        IDPScheme: formState.idpScheme
      });
    } catch (error) {
      console.error('Failed to update SSO config:', error);
    }
  };

  useDocumentTitle('SSO Integration - Perry Weather');

  if (![UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.AssistantAdmin].includes(userData?.profile?.Role)) {
    return <Redirect to='/Unauthorized' />;
  }

  if (isLoading) {
    return <Typography variant='body1'>Loading...</Typography>;
  }

//   if (isError) {
//     return <Typography variant='body1' color='error'>Failed to load SSO configuration</Typography>;
//   }

  return (
    <TrackedPage
        docTitle='SSO Configuration'
        title={
            <Typography variant='h3' color='textPrimary' noWrap>
                Single Sign-On
            </Typography>
        }
        subheader={
            <Typography variant='body1' color='textPrimary'>
                Need help? Visit our <TrackedLink className={linkClass.link} href='https://support.perryweather.com/hc' target="_blank" rel="noreferrer">
                    help center
                </TrackedLink> or contact support@perryweather.com
            </Typography>
        }>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Card>
                    <SubCardContainer
                        title='Identity Provider'
                        desc={
                            <Typography textAlign='left' variant='body2' color='textSecondary'>
                                Select your identity provider
                            </Typography>
                        }
                        >
                        <Select
                            value={formState.idpDisplayName}
                            options={[
                                {
                                    text: 'Google',
                                    value: 'Google'
                                }
                            ]}
                            onChange={(value) => setFormState({
                                ...formState,
                                idpDisplayName: value,
                                idpScheme: value
                            })}
                        />
                    </SubCardContainer>
                    <Divider style={{ margin: '24px 0' }} />
                    <SubCardContainer
                        title='Auto-Provision Users'
                        desc={
                            <Typography textAlign='left' variant='body2' color='textSecondary'>
                                Automatically create user accounts on first sign-in
                            </Typography>
                        }>
                        <Grid container spacing={8}>
                            <Grid item md={3}>
                                <Checkbox
                                    checked={formState.autoProvision}
                                    onChange={(e) => setFormState({
                                        ...formState,
                                        autoProvision: e.target.checked
                                    })}
                                />
                            </Grid>
                            <Grid item md={9}>
                                <SiteSelect
                                    value={formState.defaultUserGroupSiteId.toString()}
                                    customerId={user?.customerId ?? 0}
                                    onSelect={(value, name) => setFormState({
                                        ...formState,
                                        defaultUserGroupSiteId: parseInt(value)
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </SubCardContainer>
                    <Divider style={{ margin: '24px 0' }} />
                    <SubCardContainer
                        title='Require SSO'
                        desc={
                            <Typography textAlign='left' variant='body2' color='textSecondary'>
                                All users must sign in through your identity provider
                            </Typography>
                        }
                        >
                            <Box>
                                <Checkbox
                                    checked={formState.forceSSO}
                                    onChange={(e) => setFormState({
                                        ...formState,
                                        forceSSO: e.target.checked
                                    })}
                                />
                            </Box>
                    </SubCardContainer>

                    <Box marginTop='48px' display='flex' justifyContent='flex-end'>
                        <TrackedButton onClick={handleSubmit} disabled={isLoading}>
                            <Typography variant='body1'>Save Changes</Typography>
                        </TrackedButton>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    </TrackedPage>
  );
}

export default IntegrationsSSO;