import { Box, Typography } from '@mui/material';
import { Button, Card } from '@perry-weather/component-library';
import PermissionGate from '../../Shared/PermissionGate';
import { CustomerEntitlements } from '../../../Constants/Constants';
import { useState } from 'react';
import { useSinglewireConfigQuery, useUserQuery } from '../../../Services/API';
import { ConnectionModal } from './ConnectionModal';
import { useLocation } from 'wouter';
import { withClickTracking } from '../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../Tracked/events';

const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Integrations',
  button_feature: 'Integrations Singlewire',
  button_type: 'button',
});

export const SinglewireIntegrationCard = () => {
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const setLocation = useLocation()[1];
  const { data: user, isSuccess: isUserLoaded } = useUserQuery();
  const { data: existingSinglewireConfig } = useSinglewireConfigQuery(user?.customerId ?? 0, { skip: !isUserLoaded });
  const hasSinglewireConfig = !!existingSinglewireConfig;

  const onSinglewireContactClick = () => {
    // console.log('Contact for Pricing');
  };

  const onSinglewireConfigureClick = () => {
    setConnectionModalOpen(true);
  };

  const onSinglewireManageClick = () => {
    setLocation('/Integrations/Singlewire');
  };

  return (
    <>
      <Card header='Singlewire - InformaCast'>
        <img src='/singlewire.png' alt='Singlewire Logo' style={{ borderRadius: 4, marginBottom: 24 }} />
        <Typography variant='body1' color='textPrimary' style={{ marginBottom: 16 }}>
          Send Perry Weather policy alerts to your organization members through InformaCast
        </Typography>

        <PermissionGate
          allowedEntitlements={[CustomerEntitlements.IntegrationsSinglewire]}
          fallback={
            <Box className="singlewire-request"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <TrackedButton
                type='primary'
                onClick={onSinglewireContactClick}
                style={{width: '100%'}}
                mpExtraData={{ button_text: 'Contact for Pricing' }}>
                Contact for Pricing
                </TrackedButton>
            </Box>
          }>
          {hasSinglewireConfig ? (
            <TrackedButton
              type='primary'
              onClick={onSinglewireManageClick}
              mpExtraData={{ button_text: 'Manage Configuration' }}>
              Manage Configuration
            </TrackedButton>
          ) : (
            <TrackedButton
              type='primary'
              onClick={onSinglewireConfigureClick}
              mpExtraData={{ button_text: 'Connect to Singlewire' }}>
              Connect to Singlewire
            </TrackedButton>
          )}
        </PermissionGate>
      </Card>
      <ConnectionModal
        modalState={{
          open: connectionModalOpen,
          handleClose: () => setConnectionModalOpen(false),
          handleOpen: () => false,
        }}
      />
    </>
  );
};
